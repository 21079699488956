import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

function DownloadComponent(props) {

    function downloadFile(url, filename) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status === 200) {
                var blob = new Blob([this.response], { type: 'application/octet-stream' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        };
        xhr.send();
        props.onDone(null, null);
    }

    const documentUrl = () => {
        return props.apiUrl + "documents/" + props.conversion.outputDocumentId;
    }

    const documentName = () => {
        return props.conversion.id + "." + props.conversion.conversionType;
    }

    return (
        <div className="container border py-3 my-3 ">
            <div className="container my-3">
                <label className="form-label">Your file is ready</label>
                <div className="alert alert-success">
                    <strong>Success!</strong>
                </div>
                <button onClick={() => downloadFile(documentUrl(), documentName())} className="btn btn-success">Download file</button>
            </div>
        </div>
    );
};

export default DownloadComponent;
