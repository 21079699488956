import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

function NavigationComponent(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuClassNames = `navbar-collapse collapse ${isMenuOpen ? 'show' : ''}`;

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      {/* Hamburger menu button */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={handleMenuToggle}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* Navigation links */}
      <div className={menuClassNames}>
        <ul className="navbar-nav">
          <li className="nav-item">
            {/* eslint-disable */}
            <a href="#" className="nav-link" onClick={() => props.onMenuClick('CONVERT_PAGE')}>
              Convert
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable */}
            <a
              href="#"
              className="nav-link"
              onClick={() => props.onMenuClick('TERMS_AND_CONDITIONS')}
            >
              Terms and conditions
            </a>
          </li>
          <li className="nav-item">
            {/* eslint-disable */}
            <a href="#" className="nav-link" onClick={() => props.onMenuClick('PRIVACY_POLICY')}>
              Privacy policy
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavigationComponent;