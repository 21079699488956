import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

const UploadComponentState = {
  ACTIVE: "ACTIVE",
  NOT_ACTIVE: "NOT_ACTIVE"
}

function UploadComponent(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(UploadComponentState.NOT_ACTIVE);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileSelect = (event) => {
    if (event.target.files.length === 1) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleUploadResponse = (data) => {
    console.log("response: " + data);
    props.onDone(data, null);
  };

  const handleUploadError = (error) => {
    console.log("error: " + error.response);
    props.onDone(null, error);
  };

  const handleFileUpload = () => {
    setUploadStatus(UploadComponentState.ACTIVE);
    const url = props.apiUrl + "documents";
    const formData = new FormData();
    formData.append('file', selectedFile);
    fetch(url, {
      method: 'POST',
      body: formData
    }).then(response => {
      if (response.ok) {
        return response;
      } else {
        console.log("error: " + response.json());
        const error = new Error(response.message + " status code " + response.status);
        error.response = response;
        throw error;
      }
    }).then(response => response.json())
      .then(data => handleUploadResponse(data))
      .catch(error => {
        handleUploadError(error);
      })
      .finally(() => {
        setUploadStatus(UploadComponentState.NOT_ACTIVE)
      });
  };

  return (
    <div
      className={`container border py-3 my-3 ${isDragging ? "bg-success" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDragging(false)}
    >
      <div className="container my-3">
        <label className="form-label">Select a file or drop file to convert:</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            readOnly
            value={selectedFile?.name ?? ""}
            onChange={handleFileSelect}
          />
          <label className="input-group-text hover" htmlFor="fileInput">
            Browse
          </label>
          <input
            id="fileInput"
            type="file"
            className="form-control d-none"
            onChange={handleFileSelect}
          />
        </div>
      </div>
      <SubmitButtonComponent
        file={selectedFile}
        style={uploadStatus}
        onClick={handleFileUpload}
      />
    </div>
  );
}

function SubmitButtonComponent(props) {

  const getEnabledButton = () => {
    return <button type="button" className="btn btn-primary active" onClick={props.onClick}>Next</button>;
  }

  const getDisabledButton = () => {
    return <button type="button" className="btn btn-secondary" disabled onClick={props.onClick}>Next</button>;
  }

  const getLoadingButton = () => {
    return <button type="button" className="btn btn-primary" disabled>
      <span className="spinner-border spinner-border-sm"></span> Loading...
    </button>
  }

  const getSubmitButton = (props) => {
    if (props.file !== null) {
      if (props.style === UploadComponentState.NOT_ACTIVE)
        return getEnabledButton();
      if (props.style === UploadComponentState.ACTIVE)
        return getLoadingButton();
    } else {
      return getDisabledButton();
    }
  }
  return getSubmitButton(props)
}

export default UploadComponent;
