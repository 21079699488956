import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

function PrivacyPolicyPage() {
  return (
    <div className="container py-3 my-3">
      <h2 className="text-center">Privacy Policy</h2>
      <div className="container bg-light py-3 my-3">
        <p>This privacy policy ("Policy") explains how we collect, use, and disclose information from visitors to our website ("Site")
           who use our document conversion service ("Service"). By using the Service, you agree to the terms of this Policy.</p>
        <p>Information We Collect</p>
        <p>We do not collect any personal information from visitors to our Site. The only information we collect is the document that you upload for conversion.</p>
        <p>How We Use Your Information</p>
        <p>We use the document you upload solely for the purpose of converting it to the desired format.
           We do not use the document for any other purpose, and we do not share it with any third party.</p>
        <p>How We Protect Your Information</p>
        <p>We take reasonable measures to protect the confidentiality and security of the information we collect from you. 
          However, we do not encrypt the document that you upload for conversion, and we cannot guarantee the security of the document 
          during transmission or storage.</p>
        <p>We delete the converted document from our servers after one hour to protect your privacy.</p>
        <p>Third-Party Websites</p>
        <p>Our Site may contain links to third-party websites that are not owned or controlled by us. We are not responsible for the privacy practices 
          or content of these websites. We encourage you to read the privacy policies of these websites before providing any personal information to them.</p>
        <p>Changes to This Policy</p>
        <p>We reserve the right to modify this Policy at any time without prior notice. Any changes to this Policy will be posted on our Site, 
          and the date of the most recent revision will be indicated at the top of this page.</p>
        <p>Contact Us</p>
        <p>If you have any questions or concerns about this Policy, please contact us at [insert contact email here].</p>
        <p>Thank you for using our Service.</p>
      </div>
    </div>
  );
}
export default PrivacyPolicyPage;