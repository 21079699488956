import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

function TermsAndConditions() {
  return (
    <div className="container py-3 my-3">
      <h1 className="text-center">Terms and Conditions</h1>
      <div className="container bg-light py-3 my-3">
        <p>The tool offers converting between different document formats using open source technology.</p>
        <p>The software is provided free of charge for private use only.</p>
        <p>Commercial use of the software is prohibited, as the quality and security of the software does not meet commercial standards.</p>
        <p>The software is not to be used for the purpose of distributing content.</p>
        <p>The software stores uploaded and converted documents for a limited period of time.</p>
        <p>The provider reserves the right to make changes to these terms and conditions at any time.</p>
        <p>The provider reserves the right to modify the software at any time by restricting, changing, discontinuing, or introducing features.</p>
        <p>The software is provided free of charge, and the provider assumes no liability for the correctness and/or completeness of the processed documents; this includes any errors, omissions, inaccuracies, or similar conversion errors.
          It is the users' responsibility to review and make sure that the converted document satisfies their expectations.</p>
        <p>The software is provided free of charge. Do not use this software with private documents, confidential documents, or any documents that you do not have permission to change or distribute, as the provider does not take responsibility in case of a data breach.</p>
        <p>The provider has no obligation to continue development of the software, and it may be discontinued at any time.</p>
        <p>To use this product, you must ensure that you are entitled to use your documents within the scope of the website's offer and that you do not violate the rights of third parties, in particular copyrights.</p>
        <p>To use this product, you must ensure that the use of your documents within the scope of the website's offer does not violate official guidelines and/or legal regulations of any kind. In particular, the use of user documents with racist, pornographic, offensive, or anti-constitutional content is prohibited.</p>
        <p>The product is free of charge when using it from the website.</p>
        <p>It is forbidden to use the website's document-processing API by a third-party client.</p>
        <p>Any automated form of usage (usage by scripts, automatic page calls) of the website is forbidden.</p>
        <p>Disclaimer, The provider takes reasonable steps to protect user data and documents, but no system can be completely secure, and the provider cannot guarantee the security of user data. Therefore, the uploaded documents are deleted after a certain period of time.</p>
        <p>The provider does not claim any ownership or rights to use the documents beyond what is necessary to provide the document conversion service.
          Users retain ownership of their documents during and after the conversion of the documents</p>
        <p>The users should not use the service for sharing documents; in case they do, the provider assumes no liability</p>
        <p>Intellectual Property Rights: Users must respect the intellectual property rights of others when using the service.
          This includes, but is not limited to, refraining from using the service to infringe on copyrights, trademarks, trade secrets, patents, or other proprietary rights.
          Users must ensure that they have the necessary rights and permissions to use their documents for the purpose of document conversion,
          and must not use the service to distribute or share content that they do not have the rights to use.
          The provider reserves the right to remove any content that violates intellectual property rights or other laws or regulations, and to terminate the accounts of users who repeatedly violate these terms.</p>
        <p>In addition to the document conversion service, the website also offers eBook convert functionality. By using this service, users can convert their eBooks between various formats. The same terms and conditions mentioned above apply to the eBook conversion service.</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;