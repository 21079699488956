import React from 'react';
import ConvertPage from './pages/ConvertPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import NavigationComponent from './components/NavigationComponent';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';


function App() {

  const handleMenuClick = (props) => {
    if (props === 'CONVERT_PAGE') {
      window.location.href = "/";
    } else if (props === 'TERMS_AND_CONDITIONS') {
      window.location.href = "terms-and-conditions";
    } else if (props === 'PRIVACY_POLICY') {
      window.location.href = "privacy-policy";
    }
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div className="m-4">
            <NavigationComponent onMenuClick={handleMenuClick} />
            <ConvertPage apiUrl={apiUrl} />
          </div>
        } />
        <Route path="terms-and-conditions" element={<div className="m-4">
          <NavigationComponent onMenuClick={handleMenuClick} />
          <TermsAndConditionsPage />
        </div>} />
        <Route path="privacy-policy" element={<div className="m-4">
          <NavigationComponent onMenuClick={handleMenuClick} />
          <PrivacyPolicyPage />
        </div>} />
        <Route path="*" element={
          <div className="m-4">
            <NavigationComponent onMenuClick={handleMenuClick} />
            <ConvertPage apiUrl={apiUrl} />
          </div>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
