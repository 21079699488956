import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

function AlertComponent(props) {
  const alert = props.alert;
  if (alert.error !== null) {
    console.log("error "+alert.error);
    return (<ErrorComponent errorMessage={alert.error.message} />)
  }
  else if (alert.data !== null) {
    console.log("DISPLAYED SuccessFullAlert")
    // return (<SuccessFullAlert />)
  };
}

function SuccessFullAlert() {
  return (
    <div className="alert alert-success">
      <strong>Success!</strong> Your file has been converted and it is downloaded.
    </div>
  );
}

function ErrorComponent(props) {
  return (
    <div className="container border py-3 my-3 ">
      <div className="alert alert-danger" >
        {props.errorMessage}
      </div>
    </div>
  )
}

export default AlertComponent;
