import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import UploadComponent from '../components/UploadComponent';
import ConversionComponent from '../components/ConversionComponent';
import DownloadComponent from '../components/DownloadComponent';
import WaitForConversionComponent from '../components/WaitForConversionComponent';
import AlertComponent from '../components/AlertComponent';


function ConvertPage(props) {
  const DISPLAYED = {
    UploadComponent: "UploadComponent",
    ConversionComponent: "ConversionComponent", // rename to conversion component
    WaitForConversionComponent: "WaitForConversionComponent", // rename to conversion component
    DownloadComponent: "DownloadComponent", // rename to conversion component
  }

  // const [apiErrorMessage, setApiErrorMessage] = useState({ downloadResult: null, status: DownloadResponseStatus.EMPTY });
  const [alert, setAlert] = useState({ data: null, error: null });
  const [displayedComponent, setDisplayedComponent] = useState(DISPLAYED.UploadComponent);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [conversion, setConversion] = useState(null);


  const onUploadComponentResponse = (data, error) => {
    if (data !== null) {
      setDisplayedComponent(DISPLAYED.ConversionComponent);
      setUploadDocument(data);
    }
    setAlert({ data: data, error: error });
  }

  const onConversionComponentResponse = (data, error) => {
    if (data !== null) {
      console.log("returned conversion " + data);
      setDisplayedComponent(DISPLAYED.WaitForConversionComponent);
      setConversion(data);
    }
    setAlert({ data: data, error: error });
  }

  const onWaitForConversionDone = (data, error) => {
    if (data !== null) {
      setDisplayedComponent(DISPLAYED.DownloadComponent);
    }
    setAlert({ data: data, error: error });
  }

  const onDownloadComponentResponse = (data, error) => {
    setDisplayedComponent(DISPLAYED.UploadComponent)
    setUploadDocument(null);
    setConversion(null);
    setAlert({ data: data, error: error });
  }

  const content = () => {
    if (displayedComponent === DISPLAYED.UploadComponent && props.apiUrl != null) {
      return <UploadComponent onDone={onUploadComponentResponse} apiUrl={props.apiUrl} />
    } else if (displayedComponent === DISPLAYED.ConversionComponent) {
      return <ConversionComponent document={uploadDocument} apiUrl={props.apiUrl} onDone={onConversionComponentResponse} />
    } else if (displayedComponent === DISPLAYED.WaitForConversionComponent) {
      return <WaitForConversionComponent conversion={conversion} apiUrl={props.apiUrl} maxTimeDownloadTry={10} onDone={onWaitForConversionDone} />
    } else if (displayedComponent === DISPLAYED.DownloadComponent) {
      return <DownloadComponent conversion={conversion} apiUrl={props.apiUrl} onDone={onDownloadComponentResponse} />
    }
  }

  return (
    <div>
      <div className="container py-3 my-3">
        <h1 className="text-center">Welcome to the online document converter website</h1>
        <div className="container bg-light py-3 my-3">
          <p>Use this ebook convert website to easily convert documents between different formats, including PDF to e-reader formats and more.
            Your files, ebooks or pdf documents are never permanently retained in this system, and are continuously removed after the conversion process is complete.</p>
          <p>Please only use this tool to convert documents that you own or have the legal rights to modify.
            Uploading documents that you don't have permission to modify may be illegal and the author of this side does not accept responsibility.</p>
        </div>
        <div>
          <AlertComponent alert={alert} />
        </div>
        <div className="container bg-light py-3 my-3">
          {content()}
        </div>
      </div>
    </div>
  );
}

export default ConvertPage;