import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.css';

function WaitForConversion(props) {

    useEffect(() => {
        const constructURlForConversion = () => {
            console.log(props.apiUrl + " conversions " + props.conversion.id);
            return props.apiUrl + "conversions/" + props.conversion.id;
        };

        async function getConversion(downloadTry) {
            const maxDownload = 10;
            fetch(constructURlForConversion(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    const error = new Error(response.message + " status code " + response.status);
                    error.response = response;
                    throw error;
                }
            }).then(conversion => {
                // todo extract conversion statuses
                if (conversion.status !== "DONE") {
                    console.log("download time: " + downloadTry)
                    console.log("conversion time: " + conversion)
                    if (downloadTry < maxDownload) {
                        setTimeout(() => getConversion(downloadTry + 1), 10000)
                    }
                } else {
                    console.log("conversion is finished: " + downloadTry)
                    console.log("conversion status: " + conversion.status)
                    props.onDone(conversion, null)
                }
            }).catch(error => {
                console.log(error);
                props.onDone(null, error);
            });
        };
        console.log("useEffect")
        getConversion(0);
    }, []);

    return (
        <div className="text-center">
            <div className="spinner-border" role="status"></div>
            <div className="mt-2">Please wait while we process your request</div>
        </div>
    );
};
export default WaitForConversion;
