import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

export const ConversionResponseStatus = {
    SUCCESS: "DOWNLOAD_SUCCESS",
    ERROR: "DOWNLOAD_ERROR",
    EMPTY: "EMPTY",
}

const DownloadComponentState = {
    ACTIVE: "ACTIVE",
    NOT_ACTIVE: "NOT_ACTIVE",
    IN_PROGRESS: "IN_PROGRESS",
}

const ebookTypeModelList = [
    { name: '.pdf', value: 'pdf' },
    { name: '.epub', value: 'epub' },
    { name: '.azw3', value: 'azw3' },
    { name: '.fb2', value: 'fb2' },
    { name: '.html', value: 'htmlz' },
    { name: '.txt', value: 'txt' },
    { name: '.pdb', value: 'pdb' },
    { name: '.rb', value: 'rb' },
    { name: '.rtf', value: 'rtf' },
    { name: '.docx', value: 'docx' },
    { name: '.mobi', value: 'mobi' }
]

function ConversionComponent(props) {

    const [ebookFormat, setEbookFormat] = useState(null);

    const [convertState, setConvertState] = useState(DownloadComponentState.NOT_ACTIVE);

    const handleOnSelectedDocumentFormat = (value) => {
        setEbookFormat(value);
        setConvertState(DownloadComponentState.ACTIVE)
    };

    const constructURlForConversion = () => {
        console.log(props.apiUrl + "conversions");
        return props.apiUrl + "conversions";
    };

    const createConversion = () => {
        fetch(constructURlForConversion(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Accept': '*/*',
            },
            body: JSON.stringify(createConversionRequestJson(props.document, ebookFormat))
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            const error = new Error(response.message + " status code " + response.status);
            error.response = response;
            throw error;
        }).then(data => 
            props.onDone(data, null)
         ).catch(error => {
            props.onDone(null, error)
            console.log(error)
        });
    };

    const createConversionRequestJson = (document, conversionFormat) => {
        return {
            documentId: document.id,
            conversionType: conversionFormat
        }
    };

    return (
        <div className="container border py-3 my-3 ">
            <div className="container my-3" >
                <label className="form-label">Select the conversion format</label>
                {ebookTypeModelList.map((ebookTypeModel) =>
                    <EbookTypeModelComponent
                        ebookType={ebookTypeModel}
                        key={ebookTypeModel.value}
                        onSelect={handleOnSelectedDocumentFormat}
                    />)}
            </div>
            <SubmitButtonComponent onClick={createConversion} state={convertState} />
        </div>
    );
};

function EbookTypeModelComponent(props) {
    const handleSelect = () => {
        props.onSelect(props.ebookType.value);
    }
    return (
        <div className="radio" >
            <label>
                <input type="radio" name='group1' value={props.ebookType.value} onChange={handleSelect} />
                {props.ebookType.name}
            </label>
        </div>
    );
};

function SubmitButtonComponent(props) {

    const getEnabledButton = () => {
        return <button type="button" className="btn btn-primary active" onClick={props.onClick}>Convert</button>;
    }

    const getDisabledButton = () => {
        return <button type="button" className="btn btn-secondary" disabled >Convert</button>;
    }

    const getLoadingButton = () => {
        return <button type="button" className="btn btn-primary" disabled>
            <span className="spinner-border spinner-border-sm"></span> Please wait, Converting your documents...
        </button>
    }

    const getSubmitButton = (props) => {
        if (props.state === DownloadComponentState.NOT_ACTIVE)
            return getDisabledButton();
        if (props.state === DownloadComponentState.ACTIVE)
            return getEnabledButton();
        if (props.state === DownloadComponentState.IN_PROGRESS)
            return getLoadingButton();
    }

    return getSubmitButton(props);
};

export default ConversionComponent;
